import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1046.000000 879.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,879.000000) scale(0.100000,-0.100000)">
          <path d="M7875 4339 c-77 -27 -159 -57 -182 -65 -41 -15 -43 -18 -43 -55 0
-36 2 -40 23 -37 l22 3 0 -380 0 -380 -22 -3 c-19 -3 -23 -10 -23 -43 l0 -39
190 0 190 0 0 40 c0 38 -2 40 -30 40 l-30 0 2 227 3 227 34 23 c42 29 100 30
132 4 24 -19 24 -21 27 -250 l3 -231 -25 0 c-24 0 -26 -4 -26 -40 l0 -40 190
0 190 0 0 43 c0 40 -2 43 -27 42 l-28 0 -5 276 -5 276 -27 40 c-35 54 -86 77
-168 78 -73 0 -141 -27 -224 -91 l-46 -35 0 155 0 154 30 7 c28 6 30 9 30 56
0 27 -3 49 -7 48 -5 0 -71 -23 -148 -50z"/>
<path d="M4716 4246 c-15 -59 -139 -165 -221 -187 -32 -9 -35 -13 -35 -49 0
-38 1 -39 38 -42 l37 -3 5 -270 c4 -235 7 -274 23 -303 38 -70 128 -96 231
-68 61 18 176 80 176 96 0 5 -5 21 -12 35 -8 18 -16 24 -27 19 -36 -16 -72
-16 -96 0 l-25 16 0 240 0 240 55 0 c59 0 55 -4 69 78 l6 32 -66 0 -65 0 3 89
3 89 -35 6 c-54 9 -57 8 -64 -18z"/>
<path d="M5370 4093 c-219 -37 -359 -218 -336 -436 20 -179 158 -312 352 -340
247 -34 466 115 489 337 8 75 -8 174 -40 236 -27 53 -105 129 -163 159 -80 41
-210 60 -302 44z m84 -117 c69 -29 126 -145 146 -299 21 -159 -15 -253 -98
-251 -21 1 -48 6 -60 13 -84 45 -159 286 -134 430 18 103 68 140 146 107z"/>
<path d="M7172 4085 c-111 -31 -210 -119 -266 -235 -29 -61 -31 -72 -31 -180
0 -102 3 -121 25 -165 36 -74 114 -148 180 -171 79 -27 190 -25 253 5 63 31
136 98 182 167 l37 56 -30 31 -30 30 -39 -30 c-59 -47 -98 -63 -150 -63 -119
0 -196 116 -197 296 -1 112 45 158 116 117 36 -20 93 -115 104 -171 l6 -32
100 92 c55 51 103 98 105 106 8 20 -75 99 -134 129 -66 32 -157 39 -231 18z"/>
<path d="M6200 4074 c-25 -8 -88 -27 -140 -43 l-95 -27 -3 -43 c-3 -42 -2 -43
22 -37 l25 6 3 -242 c3 -215 5 -247 22 -278 39 -71 85 -94 186 -94 81 0 139
20 204 69 40 30 51 28 59 -15 l7 -30 155 0 155 0 0 40 c0 36 -2 40 -25 40
l-25 0 0 335 c0 312 -1 335 -17 335 -10 0 -81 -20 -158 -44 l-140 -44 -3 -41
c-3 -38 -1 -41 22 -41 l26 0 0 -192 c0 -106 -4 -198 -8 -205 -14 -23 -84 -45
-119 -38 -65 12 -68 26 -71 332 -4 307 4 284 -82 257z"/>
<path d="M5569 3186 c-3 -3 -88 -26 -189 -51 -309 -77 -290 -70 -290 -116 0
-37 2 -39 30 -39 30 0 30 -1 30 -54 l0 -53 -42 8 c-60 11 -234 11 -286 -1
-122 -29 -238 -124 -293 -242 -29 -63 -34 -85 -37 -166 -4 -88 -2 -97 27 -157
62 -125 174 -194 332 -203 104 -5 173 12 249 63 29 19 54 35 56 35 2 0 9 -18
15 -40 l12 -40 199 0 198 0 0 45 c0 45 0 45 -35 45 l-35 0 2 426 3 426 35 12
c31 10 35 15 38 50 3 35 -7 63 -19 52z m-452 -423 l28 -20 3 -220 2 -220 -57
-7 c-102 -11 -175 19 -214 88 -21 37 -24 54 -24 166 0 145 15 189 75 222 47
26 148 21 187 -9z"/>
<path d="M7147 3029 c-55 -76 -133 -127 -252 -165 l-70 -22 -3 -41 -3 -41 50
0 50 0 3 -267 c3 -262 3 -269 26 -301 36 -49 94 -75 186 -80 43 -3 104 1 135
7 54 12 231 88 231 100 0 4 -11 19 -25 34 -21 22 -30 25 -45 17 -28 -15 -103
-12 -128 6 -22 15 -22 19 -22 249 l0 234 72 3 c78 3 71 -3 92 81 l6 27 -86 0
-86 0 3 92 c2 53 -1 93 -6 94 -6 1 -30 4 -55 7 -44 4 -47 2 -73 -34z"/>
<path d="M5900 2805 c-33 -70 -60 -129 -60 -131 0 -2 24 -11 54 -20 l54 -17
58 46 c72 56 100 67 170 67 91 0 134 -33 134 -104 l0 -36 -131 0 c-332 0 -459
-73 -459 -265 0 -71 20 -115 75 -163 63 -55 144 -77 263 -70 78 5 100 10 168
44 44 22 82 40 85 42 3 1 10 -12 15 -30 l10 -33 197 -3 197 -2 0 45 c0 43 -1
45 -30 45 l-30 0 0 243 c0 214 -3 247 -19 283 -22 49 -79 98 -140 120 -67 25
-306 31 -385 11 l-58 -16 4 24 c4 20 0 24 -31 29 -20 3 -46 9 -58 12 -20 6
-28 -5 -83 -121z m410 -377 c0 -54 -6 -103 -13 -118 -27 -54 -129 -63 -183
-16 -22 18 -28 34 -32 77 -4 49 -1 59 24 88 37 44 76 60 147 61 l57 0 0 -92z"/>
<path d="M7727 2809 c-32 -67 -56 -128 -53 -134 2 -7 27 -18 55 -25 l51 -12
40 35 c64 59 96 71 182 72 70 0 80 -2 103 -26 16 -16 29 -41 32 -66 l5 -40
-178 -5 c-147 -5 -191 -9 -247 -27 -89 -29 -140 -78 -163 -157 -53 -182 73
-314 298 -314 90 0 189 28 247 69 36 26 47 22 56 -19 l7 -30 199 0 199 0 0 45
c0 43 -1 45 -29 45 l-30 0 -3 248 c-3 243 -3 248 -28 292 -32 58 -108 104
-194 119 -85 15 -247 14 -324 -3 -61 -12 -63 -12 -59 6 6 21 -13 32 -75 43
l-34 6 -57 -122z m411 -389 c-3 -87 -7 -102 -27 -124 -29 -31 -88 -42 -137
-26 -28 10 -40 22 -56 57 -47 106 27 192 166 193 l58 0 -4 -100z"/>

</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
